export const messages = {
    en: {
        login: {
            title: "Log in",
            enter_email: "Enter your Email",
            password: "Password",
            two_factor_code: "Code",
            enter_password: "Enter your Password",
            enter_code: "Enter code",
            forgot_password: "Forgot password?",
            contact_us: "For any request, please contact",
            password_recovery: "Password recovery",
            send_request: "Reset the password",
            request_was_sent: "Request was sent",
            check_email: "Please check your email for password recovery link.",
            send: "Send",
            create_new_password: "Create new password",
            confirm_password: "Confirm Password",
            confirm_password_placeholder: "Confirm your Password",
            reset_password: "Reset Password",
            set_password: "Set Password",
            save_password: "Save Password",
            password_has_been_reset: "Your password has been reset.",
            password_has_been_set: "Your password has been set.",
            can_log_in: "You can now log in and continue to use O’Sol.",
            mobile_text: "You can now close the tab and continue to use the O’Sol mobile application.",
            two_passwords_error: "Two passwords that you enter is inconsistent!",
            email_verified: "Your email has been verified",
            already_verified: "Your email is already verified",
            error: "Error",
            error_msg: "The action is not performed.",
            current_password: "Current password",
            new_password: "New Password",
            confirm_new_password: "Confirm new password",
            password_has_expired: "Password has expired",
        },
        basics: {
            showing: "Showing",
            to: "to",
            of: "out of",
            entries: "entries",
            previous: "Previous",
            next: "Next",
            entries_per_page: "Entries per page",
            select_person: "Select a person",
            profile: "Profile",
            logout: "Log Out",
            go_back: "Go back",
            back: "Back",
            filter: "Filter",
            phone_number: "Phone number",
            // edited Company
            company: "Organisation",
            select_organization: "Select an organisation",
            filter_by_organization: "Filter by Organisation Status",
            filter_by_base: "Filter by Base Status",
            filter_by_pico: "Filter by Pico Status",
            filter_by_user: "Filter by User Status",
            clear_filters: "Clear filters",
            apply_filters: "Apply filters",
            status_title: "Status",
            cancel: "Cancel",
            save_changes: "Save Changes",
            step: "Step",
            from: "/",
            general: "General Information",
            users: "Users",
            interaction: "Pico & Base settings",
            parameters: "Settings",
            hi: "Hi",
            yes: "Yes",
            qr_code_error: "Wrong QR code link",
            statistics: "Statistics",
            coming_soon: "Coming soon",
            status: {
                active: "Active",
                inactive: "Inactive",
                deleted: "Deleted",
            },
            time_units: {
                m: "m",
                h: "h",
                d: "d"
            },
            using_date: "Date of Using",
            total_time: "Total Time",
            general_tab: "General",
            history: "History of using",
            tech_info: "Technical Info",
            advanced_tab: "Advanced details",
            automation_tab: "Automation",
            template_tab: "Template setting",
            picos_history: "History",
            picos: "Picos",
            update: "Update",
            reboot: "Reboot",
            firmware_tab: "Firmware",
            hours: "Hours",
            minutes: "Minutes",
            invalid_value: "Invalid value",
            logo: "Logo",
            organization: "Organisation",
            base: "Base",
            type: "Type",
            date_range: "Date range",
            updated: "Updated",
            bases: "Bases",
            dashboard: "Dashboard",
            tickets: "Tickets",
        },
        organizations: {
            no_data: "No data",
            organization_name: "Organisation name",
            organizations_list: "List of Organisations",
            create_organization: "Create a new organisation",
            follow_steps: "Follow the simple steps below, fill in details and add information to create new Organisation.",
            saved_success: "The organisation was successfully saved.",
            created: "Created",
            create: "Create",
            save: "Save",
            details: "Details",
            organization_id: "Organisation ID",
            search_placeholder: "Find an organisation by name, manager, or email",
            empty_text: "No matches were found in the organisation list.",
            managers_name: "Manager's name",
            detail_managers_name: "Manager's name",
            create_organization_manually: "Create a new organisation manually",
            create_organization_by_link: "Send a magic link to the client",
            enter_organization_email: "Enter the client's email (manager in charge)",
            enter_email: "Enter Email",
            enter_phone_number: "Enter phone number",
            send_link: "Send unique link to organisation",
            delete_organization: "Delete organisation",
            organization_info: "Organisation's info",
            organization_status: "Organisation status",
            select_from_list: "Select from list",
            recover_password: "Recover the password",
            base_picos_setup: "O'Sol Base & Pico Setup",
            organization_deleted: "Organisation is deleted",
            domains_warning: "Users with these confirmed domain names in the email will automatically be authorized to take Pico (example: osol.co)",
            read_agree: "I have read and I agree to the conditions and accept to take the risk. I am aware that I am responsible for any loss of Pico.",
            warning_1_details: "Warning! You have disabled the lock mode, therefore Pico can be lost or stolen. Are you sure you want to proceed?",
            warning_2_details: "Warning! You have enabled recharging Pico outside of a Base, therefore Pico can be lost or stolen. Are you sure you want to proceed?",
            enter_logo_url: "Enter logo URL",
            select_digit_range: "Select digit range",
            change_to: "Change to",
            add_rule: "Add rule",
            from: "From",
            to: "To",
            value: "Value",
            advanced_tab: "Advanced settings",
            reporting_settings: "Reporting settings",
            is_monitored: "Monitor this orgnanization",
            step_1: {
                enter_name: "Enter name",
                managers_last_name: "Manager's last name",
                enter_last_name: "Enter last name",
                user_domains: "Preauthorized user email domains",
                user_categories: "User categories",
                user_category: "User category",
                user_category_upd: "User category updated",
                no_category: "No category",
                domain_names: "Domain names",
                phone_number_placeholder: "Enter phone number",
                organization_event_title: "Organisation name / Event title",
                enter_organization_name: "Enter organisation name",
                location: "Location of installation",
                country_city_address: "Country, City, Address",
                coordinates: "Сoordinates",
                pick_on_map: "Pick on Map",
                pick_radius: "Show radius on Map",
                invalid_location: "Invalid location entry!",
                link_with_organization: "Link with another organisation",
                language: "Language",
            },
            step_2: {
                lock_mode: "Lock mode",
                lockModeWarning: "Warning! If you disable the lock mode there is a high risk that your smart batteries get lost or stolen!",
                number_of_picos: "Number of Pico that can be used simultaneously by one user",
                limit_holding_time: "Limit the holding time",
                holding_time: "Holding time",
                limit_working_radius: "Limit the authorized area for Pico",
                working_radius: "Max authorized radius",
                gps_tracker: "Enable GPS and collect data",
                collect_data: "Collect data from picos",
                nfc_reader: "NFC Badge reader",
                logs_delay: "Logs delay measurement",
                minimum_soc: "Minimum SOC (%)"
            },
            step_3: {
                enable_inputs: "Enable recharging Pico by USB-C",
                two_factor_title: "Two-factor authentication",
                two_factor_for_admin: "Enable for administrators",
                usbcWarning: "Warning! If you enable the USB-C 100W input, you will allow users to recharge the battery outside of the Base. There is a high risk that your smart batteries get lost or stolen!",
                enable_outputs: "Enable specific Pico power outputs",
                wireless: "Wireless",
                charge_mode_title: "Charge mode on Base",
                ac_mode: "AC mode",
                statistic_section: "Statistics section",
                charge_mode: {
                    highest: "FAST MODE",
                    lowest: "MAX AVAILABILITY MODE",
                    eco: "ECO MODE"
                }
            },
            automation: {
                link_to_automatically_store_users: "Link to automatically create/update users",
                automation_time: "Automation time",
                connection_type: "Connection type",
                badge_reading_algorithm: "Badge reading algorithm",
                algorithm: "Algorithm",
                file_name: "File name",
                username: "Username",
                password: "Password",
                port: "Port",
                use_ssh: "Use SSH Key",
                last_import: "Last import",
                test_connection: "Test connection",
                show_ssh_key: "Show ssh key",
                create_ssh_key: "Create ssh key",
                ssh_key: "Your public key",
                not_url: "Url is empty",
                connection_timeout: "Connection timeout",
            }
        },
        bases: {
            bases_list: "List of O'Sol Base",
            add_base: "Add a New Base",
            search_placeholder: "Find a Base (by ID, organisation)",
            hardware_type: "Hardware type",
            linked_organization: "Linked Organisation",
            empty_text: "There are no matches in our Bases list.",
            no_data: "No data",
            delete_base: "Disable Base",
            no_organization: "Not selected",
            managers_full_name: "Manager's name and last name",
            base_qr_code: "The unique QR Code for the Base is:",
            download_png: "Download PNG Image",
            save_base: "Save Base info",
            not_selected: "No organisation",
            location: "Location of installation",
            base_deleted: "Base is deleted",
            release_slot: "Number of Picos: ",
            release_slot_message: "Release slot",
            slot_released: "Slot of the base has been released.",
            slot: "Slot on the base station",
            update_request_sent: "The update request has been sent. Please wait for the update or synchronization procedure completion. This may take about a minute.",
            reboot_request_sent: "The reboot request has been sent. Please wait for the reboot procedure completion. This may take about a minute.",
            push_nfc_request_sent: "The download badges request has been sent. Please wait for the update or synchronization procedure completion. This may take about a minute.",
            last_updated: 'Last updated',
            reboot_confirm: "Are you sure you want to reboot the base ?",
            unlock_all_picos_confirm: "Are you sure you want to unlock all picos? Make sure you are in front of the correct Base station:",
            all_picos_unlocked: "All slots of the base have been released.",
            base_informations: "Base informations",
            pico_version: "Pico version",
            pico_versions: "Pico versions",
            change_version: "Change version",
            firmware_info: "Firmware info",
            auto_reboot: "Auto reboot",
            screensaver: "Screensaver",
            firmware_version: "Firmware version",
            mac_wifi: "Mac Wifi",
            mac_ethernet: "Mac Ethernet",
            ip_address: "Ip Address",
            maintenance_mode: "Maintenance mode",
            auth_local: "Auth local",
            status: {
                active: "Active",
                low_activity: "Low activity",
                offline: "Offline",
                off: "Off | Maintenance",
            },
            push_nfc: "Download badges",
        },
        picos: {
            picos_list: "List of O'Sol Pico",
            pico_id: "Pico ID",
            alias: "Alias",
            storage_base_id: "Storage Base ID",
            search_placeholder: "Search the pico by ID, storage base ID",
            empty_text: "There are no matches in our Picos list",
            delete_pico: "Disable pico",
            user_id: "User ID",
            pico_deleted: "Pico is deleted",
            locked: "Locked",
            unlocked: "Unlocked",
            deleted: "Deleted",
            pico_released: "Pico was released",
            no_data: "No data",
            base_id_from: "From base",
            base_id_to: "To base",
            release: "Release the pico",
            unlock_all: "Unlock ALL picos",
            barcode: "The unique Barode for the Pico is:",
            save_pico: "Save Pico info",
        },
        users: {
            users_list: "List of users",
            upload_file: "Upload from Excel file",
            download_template: "Template",
            search_placeholder: "Search for a user by ID, Name, Email or NFC Badge",
            empty_text: "There are no matches in our Users list.",
            users_imported: "Users were successfully imported!",
            users_import_started: "Users import started!",
            user_email: "User Email",
            delete_user: "Delete user",
            user_info: "User info",
            nfc_badge: "NFC Badge ID",
            nfc_placehodler: "Enter NFC Badge ID",
            user_saved: "User is saved successfully",
            first_name: "First name",
            first_name_placeholder: "Enter first name",
            last_name: "Last Name",
            last_name_placeholder: "Enter last name",
            user_status: "User status",
            user_id_placeholder: "Enter User ID",
            user_deleted: "User is deleted",
            assignAsOrganizer: "Assign as the Organizer in a linked Organisation",
            create_user: "Create user",
            personal_info: "Personal info",
        },
        messages: {
            userHavePico: "The user must return all pico's in order to change the organisation",
            wrongCredentials: 'Credentials do not match or user doesn’t exist',
            wrongLimitAttempts: "Too Many Attempts",
            emailDoesntExist: 'This email does not exist in the database',
            passwordRegExp: 'Should contain letters and digits, min 5 and max 15 characters',
            passwordRequired: 'Please enter your password.',
            codeRequired: 'Please enter the code.',
            requiredEmail: 'Email is required.',
            requiredField: 'This field is required.',
            passwordConfirm: 'Please confirm your password!',
            emailNotValid: 'The input is not valid E-mail!',
            emailIsInvalid: 'Invalid email entry!',
            passwordIsNotValid: 'Password is not valid!',
            minLength: 'Min length of a field - ',
            maxLength: 'Max length of a field - ',
            domainIsNotValid: 'The domain name has not been identified!',
            profileIsTooLong: "No more than 5 tags, max length of a category is 12 symbols.",
            domainIsRestricted: 'Restricted domain names cannot be added!',
            userIdInteger: 'The User ID must be an integer!',
            //Modal messages
            logout: 'Are you sure you want to log out?',
            logoutOk: "Yes, Log Out",
            registerLinkSent: 'Registration link has successfully been sent to the client.',
            deleteOrganizationMassages: 'This Organisation will be deleted. Are you sure you want to continue?',
            deleteBaseMassages: 'This Base will be disabled. Are you sure you want to continue?',
            deleteUserMassages: 'This user will be deleted. Are you sure you want to continue?',
            deletePicoMassages: 'This pico will be disabled. Are you sure you want to continue?',
            emailWasSent: 'Email for the recovery password was sent. Please check your email!',
            userDeleteError: 'The user must return all pico’s in order to delete the user',
            userUpdateError: 'The user must return all pico’s in order to change the status',
            releasePico: 'Pico will be unlocked from the base station and get deleted status',
            invalidPhoneNumber: 'Invalid phone number',
            userWithThisEmailLinkedToAnotherOrganization: 'The user with this e-mail is linked to another organisation',
            invalidUrl: 'URL is invalid',
            invalidImageUrl: 'Image URL is invalid',
            invalidPngFormat: '.png is the only type accepted',
            invalidImageSize: 'Max image size should be 200x200',
            import_template_change: 'Make changes to the auto import template?',
            picoUpdatedSuccess:'Pico is updated successfully',
            ifPicoUpdate:'Pico will be update'
        },
        statistics: {
            title: "Statistics",
            show: "Show statistic",
            no_data: "No data found",
            type: {
                general: "General",
                users: "Users",
                picos: "Picos",
            },
            general: {
                title: "General Stats",
                operations_count: "Number of operations (pico taken + returned)",
                base_changing_pct: "Percentage of Pico changing Base (% of nbr pico taken)",
                distinct_users_count: "Number of distinct users",
                users_count: "Total number of users in database",
                avg_duration: "Average duration of use",
                avg_consumption: "Average consumption (in % of SOC)",
                avg_movement: "Average movement of Pico (in m)",
                avg_charge_taken: "Average state of charge (SOC) when Pico is taken",
                avg_charge_returned: "Average state of charge (SOC) when Pico is returned",
                nfc_pct: "% of use by NFC Badge",
                app_pct: "% of use by app",
                unauthanticated_pct: "% of use without authentication",
                avg_peak_hour: "Average peak hour",
                saturation_level: "Saturation level (% of time when picos are unavailable within a normal day of work)",
            },
            users: {
                title: "Top 10 users",
                time_title: "Number of operations categorized per duration of use",
                user_id: "User ID",
                operations_count: "Number of operations",
                total_hours_use: "Total time of use",
                max_use: "Max use",
            },
            time: {
                total: "Total of operations",
                l_5: "Less than 5 min",
                bm_5_10: "Between 5 and 10 minutes",
                bm_10_30: "Between 10 and 30 minutes",
                bm_30_60: "Between 30 min and 1 hour",
                bh_1_10: "Between 1 hour and 10 hours",
                bh_10_24: "Between 10 hours and 24hours",
                mh_24: "More than 24h",
            }
        }
    },
    fr: {
        login: {
            title: "Connexion",
            enter_email: "Entrez votre Email",
            two_factor_code: "Code",
            password: "Mot de passe",
            enter_password: "Entrez votre mot de passe",
            enter_code: "Entrer le code",
            forgot_password: "Mot de passe oublié?",
            contact_us: "Pour toute demande, merci de contacter",
            password_recovery: "Récupération du mot de passe",
            send_request: "Réinitialiser le mot de passe",
            request_was_sent: "La demande a été envoyée",
            check_email: "Consultez votre boîte mail et validez le lien de récupération qui vous a été envoyé.",
            send: "Envoyer",
            create_new_password: "Crée un nouveau mot de passe",
            confirm_password: "Confirmer le mot de passe",
            confirm_password_placeholder: "Confirmer votre mot de passe",
            reset_password: "Réinitialiser le mot de passe",
            set_password: "Définir le mot de passe",
            save_password: "Enregistrer le mot de passe",
            password_has_been_reset: "Votre mot de passe a été réinitialisé.",
            password_has_been_set: "Votre mot de passe a été défini.",
            can_log_in: "Vous pouvez maintenant vous connecter et continuer à utiliser O’Sol.",
            mobile_text: "Vous pouvez maintenant fermer l’onglet et continuer à utiliser l’application mobile O’Sol.",
            two_passwords_error: "Deux mots de passe que vous entrez ne sont pas cohérents!",
            email_verified: "Votre email a été vérifié",
            already_verified: "Votre email est déjà vérifié",
            error: "Erreur",
            error_msg: "L'action n'est pas effectuée.",
            current_password: "Mot de passe actuel",
            new_password: "Nouveau mot de passe",
            confirm_new_password: "Confirmer le nouveau mot de passe",
            password_has_expired: "Le mot de passe a expiré",
        },
        basics: {
            showing: "Montrer",
            to: "à",
            of: "sur",
            entries: "éléments",
            previous: "Précédent",
            next: "Suivant",
            entries_per_page: "Elements par page",
            select_person: "Sélectionnez une personne",
            profile: "Profil",
            logout: "Déconnexion",
            go_back: "Retourner",
            back: "Retour",
            filter: "Filtrer",
            phone_number: "Numéro de téléphone",
            company: "Organisation",
            select_organization: "Sélectionner",
            filter_by_organization: "Filtrer par Statut de l'organisation",
            filter_by_base: "Filtrer par Statut de base",
            filter_by_pico: "Filtrer par Statut de pico",
            filter_by_user: "Filtrer par Statut de utilisateurs",
            clear_filters: "Réinitialiser les filtres",
            apply_filters: "Appliquer les filtres",
            status_title: "Statut",
            cancel: "Annuler",
            save_changes: "Sauvegarder les modifications",
            step: "Étape",
            from: " sur ",
            general: "Informations générales",
            interaction: "Paramètres Pico & Base",
            parameters: "Paramètres",
            hi: "Salut",
            yes: "Oui",
            qr_code_error: "Lien de code QR incorrect",
            statistics: "Statistiques",
            coming_soon: "Bientôt disponible",
            status: {
                active: "Actif",
                inactive: "Inactif",
                deleted: "Supprimé",
            },
            time_units: {
                m: "m",
                h: "h",
                d: "j"
            },
            using_date: "Date d'utilisation",
            total_time: "Temps total",
            general_tab: "Générale",
            users: "Utilisateurs",
            history: "Histoire d'utilisation",
            tech_info: "Technical Info",
            advanced_tab: "Informations complémentaires",
            automation_tab: "Automatisation",
            template_tab: "Paramètres du modèle",
            picos_history: "History",
            picos: "Picos",
            update: "Mettre à jour",
            reboot: "Redémarrer",
            firmware_tab: "Micrologiciel",
            hours: "Les heures",
            minutes: "Minutes",
            invalid_value: "Valeur invalide",
            logo: "Logo",
            organization: "Organization",
            base: "Base",
            type: "Taper",
            date_range: "Plage de dates",
            updated: "Actualisé",
            bases: "Bases",
            dashboard: "Tableau de bord",
            tickets: "Tickets",
        },
        organizations: {
            no_data: "Pas de données",
            organization_name: "Nom de l'organisation",
            organizations_list: "Liste des organisations",
            create_organization: "Créer une nouvelle organisation",
            follow_steps: "Suivez les étapes ci-dessous, complétez les élements manquants et ajoutez les informations nécessaires pour créer une nouvelle organisation.",
            saved_success: "L'organisation a été enregistrée avec succès.",
            created: "Crée",
            create: "Créer",
            save: "Enregistrer",
            details: "Détails",
            organization_id: "ID de l'organisation",
            search_placeholder: "Trouver une organisation par son nom, manager, ou adresse email",
            empty_text: "Aucun match n'a été trouvé dans notre base de données.",
            managers_name: "Nom du responsable",
            detail_managers_name: "Prénom du responsable",
            create_organization_manually: "Créer une nouvelle organisation manuellement",
            create_organization_by_link: "Envoyer un lien magique au client",
            enter_organization_email: "Entrez l'email du client responsable",
            enter_email: "Entrez l'email",
            enter_phone_number: "Entrez un numéro de téléphone",
            send_link: "Envoyer un lien unique au client",
            delete_organization: "Supprimer l'organisation",
            organization_info: "Informations sur l'organisation",
            organization_status: "Statut de l'organisation",
            select_from_list: "Sélectionner dans la liste",
            recover_password: "Récupérer le mot de passe",
            base_picos_setup: "Paramètres O'Sol Base & Pico",
            organization_deleted: "L'organisation est supprimée",
            domains_warning: "Les utilisateurs ayant ces noms de domaine confirmés dans l'email seront automatiquement autorisés à prendre Pico (exemple : osol.co)",
            read_agree: "J'ai lu les conditions et j'accepte de prendre le risque. Je prends la responsabilité en cas de perte de Pico.",
            warning_1_details: "Attention! Vous avez désactivé le mode verouillé. Il y a de fortes chances pour que des batteries soient perdues ou volées. Etes vous sûr de vouloir continuer?",
            warning_2_details: "Attention! Vous avez activé la recharge des batteries hors Base. Il est très probable que des batteries soient perdues ou volées. Etes vous sûr de vouloir continuer?",
            enter_logo_url: "Entrez l'URL du logo",
            select_digit_range: "Sélectionner la plage de chiffres",
            change_to: "Passer à",
            add_rule: "Ajouter une règle",
            from: "À partir de",
            to: "Pour",
            value: "Évaluer",
            advanced_tab: "Paramètres avancés",
            reporting_settings: "Paramètres de rapport",
            is_monitored: "Suivre cette organisation",
            step_1: {
                enter_name: "Entrez un prénom",
                managers_last_name: "Nom de famille du responsable",
                enter_last_name: "Entrez un nom de famille",
                user_domains: "Nom de domaines utilisateurs préautorisés ",
                user_categories: "Catégories d'utilisateurs",
                user_category: "Catégorie d'utilisateur",
                user_category_upd: "Catégorie d'utilisateur mise à jour",
                no_category: "Aucune catégorie",
                domain_names: "Nom de domaines",
                phone_number_placeholder: "Entrez un numéro de téléphone",
                organization_event_title: "Nom de l'organisation / Évènement",
                enter_organization_name: "Entrez le nom de l'organisation",
                location: "Lieu d'installation",
                country_city_address: "Pays, Ville, Adresse",
                coordinates: "Сoordonnées",
                pick_on_map: "Choisissez sur la carte",
                pick_radius: "Afficher le rayon sur la carte",
                invalid_location: "Entrée de lieu non valide!",
                link_with_organization: "Lier le compte à une autre organisation",
                language: "Langue",
            },
            step_2: {
                lock_mode: "Mode verrouillé",
                lockModeWarning: "Attention! Si vous désactivez le mode verouillé, les batteries seront libres d'accès sans besoin d'authentification. Il y a un risque important de perte ou de vol de Pico.",
                number_of_picos: "Nombre de Pico pouvant être utilisés en simultané par un utilisateur",
                limit_holding_time: "Définir la durée d'utilisation",
                holding_time: "Durée d'utilisation",
                limit_working_radius: "Définir la zone géographique d'utilisation pour Pico",
                working_radius: "Rayon maximal autorisé",
                gps_tracker: "Activer le GPS et collecter les données",
                collect_data: "Collect data from picos",
                nfc_reader: "Lecteur de badge NFC",
                logs_delay: "Délais entre chaque mesure",
                minimum_soc: "État de charge minimal (%)"
            },
            step_3: {
                enable_inputs: "Activer la recharge USB-C du Pico",
                two_factor_title: "Authentification à deux facteurs",
                two_factor_for_admin: "Activer pour les administrateurs",
                usbcWarning: "Attention! Si vous activez la recharge par USB-C 100W, vos utilisateurs pourront recharger la batterie sans passer par le Base. Il y a un risque important de perte ou de vol de Pico.",
                enable_outputs: "Activer des ports spécifiques sur Pico",
                wireless: "Recharge sans fil",
                charge_mode_title: "Mode de charge sur Base",
                ac_mode: "Mode CA",
                statistic_section: "Section statistiques",
                charge_mode: {
                    highest: "Mode potentiel max",
                    lowest: "Mode disponibilité max",
                    eco: "Mode économie"
                }
            },
            automation: {
                link_to_automatically_store_users: "Lien pour créer/mettre à jour automatiquement les utilisateurs",
                automation_time: "Temps d'automatisation",
                connection_type: "Type de connexion",
                badge_reading_algorithm: "Badge reading algorithm",
                algorithm: "Algorithme",
                file_name: "Nom de fichier",
                username: "Nom d'utilisateur",
                password: "Mot de passe",
                use_ssh: "Utiliser la clé SSH",
                port: "Port",
                last_import: "Dernière importation",
                test_connection: "Tester la connexion",
                show_ssh_key: "Afficher la clé ssh",
                create_ssh_key: "Créer une clé ssh",
                ssh_key: "Votre clé publique",
                not_url: "L'URL est vide",
                connection_timeout: "Délai de connection dépassé",
            }
        },
        bases: {
            bases_list: "Liste des O'Sol Base",
            add_base: "Ajouter un nouveau O'Sol Base",
            search_placeholder: "Trouver un Base (par ID, organisation)",
            hardware_type: "Type matériel",
            linked_organization: "Organisation liée",
            empty_text: "Il n'y a pas de correspondance dans notre liste de bases.",
            no_data: "Pas de données",
            delete_base: "Désactiver la Base",
            no_organization: "Non selectionné",
            managers_full_name: "Nom du responsable",
            base_qr_code: "Le QR Code unique pour ce Base est:",
            download_png: "Télécharger l'image PNG",
            save_base: "Enregistrer les infos du Base",
            not_selected: "Non selectionné",
            location: "Lieu d'installation",
            base_deleted: "Base is deleted",
            release_slot: "Number of Picos: ",
            release_slot_message: "Release slot",
            slot_released: "Les fentes sur la base ont été libérées.",
            slot: "Slot on the base station",
            update_request_sent: "La demande de mise à jour a été envoyée. Veuillez attendre la fin de la procédure de mise à jour ou de synchronisation. Cela peut prendre environ une minute.",
            reboot_request_sent: "La demande de redémmarage a été envoyée. Veuillez attendre la fin de la procédure de redémmarage. Cela peut prendre environ une minute.",
            push_nfc_request_sent: "La demande de télécharger les badges a été envoyée. Veuillez attendre la fin de la procédure de mise à jour ou de synchronisation. Cela peut prendre environ une minute.",
            last_updated: 'Dernière mise à jour',
            reboot_confirm: "Vous êtes sûr de vouloir redémmarer la base ?",
            unlock_all_picos_confirm: "Vous êtes sûr de vouloir débloquer tous les picos ? Assurez-vous que vous êtes devant la bonne station de base:",
            all_picos_unlocked: "Tous les fentes de la base ont été libérées.",
            base_informations: "Informations sur la base",
            pico_version: "Version pico",
            pico_versions: "Versions picos",
            change_version: "Changer de version",
            firmware_info: "Informations sur le micrologiciel",
            auto_reboot: "Réinitialisation automatique",
            screensaver: "Économiseur d'écran",
            firmware_version: "Version logiciel",
            mac_wifi: "Mac Wifi",
            mac_ethernet: "Mac Ethernet",
            ip_address: "Adresse IP",
            maintenance_mode: "Mode de Maintenance",
            auth_local: "Auth local",
            status: {
                active: "Actif",
                low_activity: "Faible activité",
                offline: "Hors ligne",
                off: "Désactivé | Entretien",
            },
            push_nfc: "Télécharger les badges",
        },
        picos: {
            picos_list: "Liste des O'Sol Pico",
            pico_id: "ID du Pico",
            alias: "Alias",
            storage_base_id: "ID du Base de stockage",
            search_placeholder: "Trouver un Pico par ID, ID du Base de stockage",
            empty_text: "Il n'y a pas de correspondance dans notre liste Picos",
            delete_pico: "Désactiver Pico",
            user_id: "ID utilisateur",
            pico_deleted: "Pico is deleted",
            locked: "Verrouillé",
            unlocked: "Déverrouillé",
            deleted: "Supprimé",
            pico_released: "Pico was released",
            no_data: "No data",
            base_id_from: "From base",
            base_id_to: "To base",
            release: "Release the pico",
            unlock_all: "Débloquez TOUS les picos",
            barcode: "The unique Barode for the Pico is:",
            save_pico: "Enregistrer les infos du Pico",
        },
        users: {
            users_list: "Liste des utilisateurs",
            upload_file: "Importer un fichier Excel",
            download_template: "Modèle",
            search_placeholder: "Rechercher un utilisateur via ID, Nom, Email ou Badge NFC",
            empty_text: "There is no match in our user list.",
            users_imported: "Les utilisateurs ont été importés avec succès!",
            users_import_started: "L'importation des utilisateurs a commencé!",
            user_email: "Email utilisateur",
            delete_user: "Supprimer l'utilisateur",
            user_info: "Informations utilisateur",
            nfc_badge: "ID de Badge NFC",
            nfc_placehodler: "Entrer l'ID du badge NFC",
            user_saved: "L'utilisateur a été enregistré avec succès",
            first_name: "Prénom",
            first_name_placeholder: "Entrez prénom",
            last_name: "Nom de famille",
            last_name_placeholder: "Entrer le nom de famille",
            user_status: "Statut de l'utilisateur",
            user_id_placeholder: "Entrez l'ID utilisateur",
            user_deleted: "L'utilisateur est supprimé",
            assignAsOrganizer: "Assigner comme Organisateur dans une Organisation liée",
            create_user: "Créer un utilisateur",
            personal_info: "Informations personnelles",
        },
        messages: {
            userHavePico: "L'utilisateur doit rendre tous les pico's afin de modifier l'organisation",
            wrongCredentials: "Les données ne correspondent pas ou l'utilisateur n'existe pas.",
            wrongLimitAttempts: "Trop d'essais",
            emailDoesntExist: "Cet email n'existe pas dans la base de données.",
            passwordRegExp: 'Doit contenir entre 5 et 15 caractères incluant des chiffres et des lettres',
            passwordRequired: "Veuillez entrer votre mot de passe!",
            codeRequired: 'Veuillez entrer le code.',
            requiredEmail: "L'email est obligatoire.",
            requiredField: "Ce champ est obligatoire.",
            passwordConfirm: "Veuillez confirmer votre mot de passe",
            emailNotValid: "L'email semble ne pas être conforme",
            emailIsInvalid: "Email invalide",
            passwordIsNotValid: "Le mot de passe n'est pas conforme",
            minLength: "Longueur minimum du champ - ",
            maxLength: "Longueur maximale du champ - ",
            domainIsNotValid: "Le nom de domaine n'a pas été identifié.",
            profileIsTooLong: "Pas plus de 5 balises, la longueur maximale d'une catégorie est de 12 symboles.",
            domainIsRestricted: "Les noms de domaine restreints ne peuvent pas être ajoutés !",
            userIdInteger: "L'User ID doit être un nombre entier.",
            //Modal messages
            logout: "Êtes-vous sûr de vouloir vous déconnecter?",
            logoutOk: "Oui, déconnectez-vous",
            registerLinkSent: "Le lien d'inscription a bien été envoyé au client.",
            deleteOrganizationMassages: "Cette organisation sera supprimée. Êtes-vous sûr de vouloir continuer?",
            deleteBaseMassages: "Ce Base sera désactivé. Êtes-vous sûr de vouloir continuer?",
            deleteUserMassages: "Cet utilisateur sera supprimé. Êtes-vous sûr de vouloir continuer?",
            deletePicoMassages: "Ce Pico sera désactivé. Êtes-vous sûr de vouloir continuer?",
            emailWasSent: "Nous vous avons envoyé un email pour récupérer votre mot de passe. Consultez votre boîte mail.",
            userDeleteError: "L’utilisateur doit rendre tous les pico’s pour pouvoir supprimer l’utilisateur",
            userUpdateError: 'L’utilisateur doit retourner tous les pico’s afin de changer le statut',
            releasePico: 'Pico will be unlocked from the base station and get deleted status',
            invalidPhoneNumber: 'Numéro de téléphone invalide',
            userWithThisEmailLinkedToAnotherOrganization: 'L’utilisateur avec cet e-mail est lié à une autre organisation',
            invalidUrl: 'L\'URL n\'est pas valide',
            invalidImageUrl: 'L\'URL de l\'image n\'est pas valide',
            invalidPngFormat: '.png est le seul type accepté',
            invalidImageSize: 'La taille maximale de l\'image doit être de 200 x 200',
            import_template_change: 'Apporter des modifications au modèle d\'importation automatique?',
            picoUpdatedSuccess:'Pico est mis à jour avec succès',
            ifPicoUpdate:'Pico sera mis à jour'
        },
        statistics: {
            title: "Statistiques",
            show: "Afficher la statistique",
            no_data: "Aucune donnée disponible",
            general: {
                title: "Statistiques générales",
                operations_count: "Nombre d'opérations (pico pris + rendu)",
                base_changing_pct: "Pourcentage de pico changeant de base (% du nbr pico pris)",
                distinct_users_count: "Nombre d'utilisateurs distincts",
                users_count: "Nombre total d'utilisateurs dans la base de données",
                avg_duration: "Durée moyenne d'utilisation",
                avg_consumption: "Consommation moyenne (en % du COS)",
                avg_movement: "Déplacement moyen du Pico (en m)",
                avg_charge_taken: "État de charge moyen (SOC) lorsque Pico est pris",
                avg_charge_returned: "État de charge moyen (SOC) au retour du Pico",
                nfc_pct: "% d'utilisation par Badge NFC",
                app_pct: "% d'utilisation par application",
                unauthanticated_pct: "% d'utilisation sans authentification",
                avg_peak_hour: "Heure de pointe moyenne",
                saturation_level: "Niveau de saturation (% du temps pendant lequel les picos sont indisponibles au cours d'une journée normale de travail)",
            }
        }
    }
}
